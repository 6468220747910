import React, { useEffect, useRef, useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import Spinner from 'components/Spinner';
import Translation from 'locales/Translation';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';

import "./styles.scss";

const { useTranslation } = Translation.setup();
const REQUEST_PAGE_SIZE = 200; // max number of results Form.io can handle for assessments request
const TABLE_PAGE_SIZE = 50;

const InternalPagination = createUltimatePagination({
  WrapperComponent: Pagination,
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: ({ value, isActive }) => (
      <Pagination.Item data-value={value} active={isActive}>{value}</Pagination.Item>
    ),
    [ITEM_TYPES.ELLIPSIS]: ({ value, isActive, onClick }) => (
      <Pagination.Ellipsis data-value={value} onClick={onClick}/>
    ),
    [ITEM_TYPES.FIRST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.First data-value={1} disabled={isActive} onClick={onClick}/>
    ),
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: ({ value, isActive, onClick }) => (
      <Pagination.Prev data-value={value} disabled={isActive} onClick={onClick}/>
    ),
    [ITEM_TYPES.NEXT_PAGE_LINK]: ({ value, isActive, onClick }) => (
      <Pagination.Next data-value={value} disabled={isActive} onClick={onClick}/>
    ),
    [ITEM_TYPES.LAST_PAGE_LINK]: ({ value, isActive, onClick }) => (
      <Pagination.Last data-value={value} disabled={isActive} onClick={onClick}/>
    ),
  },
});

export function PaginatedTable(props) {
  const {
    pageNumber,
    children,
    columns,
    limit,
    rows,
    totalRecords,
    onPaginationButtonClick,
    showSpinner,
    isSearching,
    t,
    className,
  } = props;
  const totalPages = Math.ceil(totalRecords / limit);

  if (rows === undefined || totalRecords === undefined) {
    return <Spinner />;
  }

  const generateColumnHeader = (column, idx) => (
    <th key={idx} className={column.className}>
      {column.title}
    </th>
  );

  return (
    <React.Fragment>
      <div className="regenagri-table-pagination-controls">
        <InternalPagination
          currentPage={Math.min(totalPages, pageNumber + 1)}
          totalPages={totalPages}
          onClick={onPaginationButtonClick}
          hidePreviousAndNextPageLinks
          hideFirstAndLastPageLinks
          siblingPagesRange={1}
        />
        <div className="regenagri-table-total-records">
          {isSearching && <span>Searching...</span>}
          ({t('totalRecordsWithCount_plural', { count: totalRecords })})
        </div>
      </div>
      <Table className={className || null}>
        <thead>
          <tr>
            { columns.map((column, idx) => generateColumnHeader(column, idx)) }
          </tr>
        </thead>
        <tbody>
          { children(rows) }
          { showSpinner && <tr><td><Spinner /></td></tr> }
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default function PaginatedTableWrapper(props) {
  const {
    children,
    columns,
    fetchData,
    fetchFinally,
    setError,
    limit,
    className,
    farm,
    completed,
    certified,
    selectedFilterData,
  } = props;

  const { t } = useTranslation();

  const [rows, setRows] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(undefined);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPageIsLoading, setCurrentTablePageIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const allDataRef = useRef([]);
  const downloadJobRef = useRef(null);

  // The download process uses pageNumber value from when the loop started in each iteration, even if it
  // has changed in the meantime. However, it does "see" the changes in Refs, so the pageNumber value is
  // duplicated in a Ref hook for use in the background download
  const pageNumberRef = useRef(0);

  /**
   * The functionality of fetching multiple pages of results in the background is needed for displaying
   * a flttered the list of assessments. The data in the table comes from two different documents/forms in
   * Form.io: Assessment and Profile Information, and it is combined by the backend client.
   * If the list is only filtered by attributes stored in the Assessment documents, the filters can be added
   * directly to the Form.io request, so navigating to a page of the results (in the table) only triggers a single
   * request with all the filters applied (fetchAllRowsSinglePage function).
   * However, if there are other filters in use, it is only possible to apply them once the results have
   * been fetched. Using those filters triggers multiple requests that fetch all the relevant Assessment data
   * page by page until all results are fetched or the process is interrupted by changing filters
   * (fetchAllRowsMultiplePages). The results of all requests are stored in allDataRef so they can be paginated in
   * the table. This process allows the user to see assessments in the search results as soon as they are found,
   * without having to wait for the whole search to be completed.
   */

  const fetchMultiple = Boolean(
    selectedFilterData?.country?.length
    || selectedFilterData?.createdYear?.length
    || selectedFilterData?.typeOfFarm?.length
  );

  // reset values if filters have been changed (interrupts the current download loop)
  useEffect(() => {
    allDataRef.current = [];
    downloadJobRef.current = null;
    setPageNumber(0);
    pageNumberRef.current = 0;
  }, [selectedFilterData]);

  useEffect(() => {
    if (!fetchMultiple) {
      fetchAllRowsSinglePage();
    } else if (!downloadJobRef.current) {
      fetchAllRowsMultiplePages();
    }
  }, [fetchData, fetchFinally, pageNumber, limit, farm, completed, certified, selectedFilterData, fetchMultiple]);

  const getNewSliceOfResults = (newPageNumber) => allDataRef.current.slice(
    newPageNumber * TABLE_PAGE_SIZE,
    (newPageNumber + 1) * TABLE_PAGE_SIZE,
  );

  const onPaginationButtonClick = (event) => {
    const a = event.target;
    const newPageNumber = a.dataset.value ? parseInt(a.dataset.value, 10) : parseInt(a.parentNode.dataset.value, 10);
    setPageNumber(newPageNumber - 1);
    pageNumberRef.current = newPageNumber - 1;
    if (fetchMultiple) {
      setRows(getNewSliceOfResults(newPageNumber - 1));
    }
  };

  // make a single network request and display results
  const fetchAllRowsSinglePage = async () => {
    try {
      const { data, totalRecords: queryTotalRecords } = await fetchData(
        limit,
        pageNumber * limit,
        farm,
        completed,
        certified
      );
      setRows(data);
      setTotalRecords(queryTotalRecords);
    } catch (err) {
      setError(`${t('An error occurred whilst trying to get user information:')} ${t(err.message)}`);
    } finally {
      fetchFinally();
    }
  };

  // keep fetching results in the background
  const fetchAllRowsMultiplePages = async () => {
    let lastPageOfResults = false;
    let currentSkip = 0;
    const downloadJobId = Date.now();
    downloadJobRef.current = downloadJobId;
    while (
      !lastPageOfResults
      && downloadJobId === downloadJobRef.current // interrupt the loop if new search has been triggered
    ) {
      try {
        setIsSearching(true);
        setTotalRecords(allDataRef.current.length);
        // eslint-disable-next-line no-await-in-loop
        const res = await fetchData(REQUEST_PAGE_SIZE, currentSkip, farm, completed, certified);
        if (downloadJobId === downloadJobRef.current) { // make sure new search was not triggered in the meantime
          allDataRef.current = [...allDataRef.current, ...res.data];
        }
        const currentTablePage = getNewSliceOfResults(pageNumberRef.current);
        if (currentTablePage.length === TABLE_PAGE_SIZE) {
          setCurrentTablePageIsLoading(false);
        } else {
          setCurrentTablePageIsLoading(true);
        }
        setRows(currentTablePage);
        lastPageOfResults = res.lastPageOfResults;
        currentSkip += REQUEST_PAGE_SIZE;
      } catch (err) {
        setError(`${t('An error occurred whilst trying to get user information:')} ${t(err.message)}`);
      } finally {
        fetchFinally();
      }
    }
    setTotalRecords(allDataRef.current.length);
    setCurrentTablePageIsLoading(false);
    setIsSearching(false);
  };

  return <PaginatedTable
    pageNumber={pageNumber}
    columns={columns}
    limit={limit}
    rows={rows}
    totalRecords={totalRecords}
    onPaginationButtonClick={onPaginationButtonClick}
    t={t}
    className={className}
    showSpinner={currentPageIsLoading}
    isSearching={isSearching}
  >
    { children }
  </PaginatedTable>;
}
