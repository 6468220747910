import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexts/Auth';
import { AssessmentAdminActionsMenu } from './AdminActionsMenu';
import { AssessmentActionButtons } from './ActionsButtonGroup';

export function ActionsButton({
  assessment,
  patchAssessment,
  setAssessmentPath,
  setError,
  currentUserId,
  userIsInAGroup,
  setAssessmentToChangeGroupFor,
  setAssessmentToChangeOwnerOf,
}) {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  if (auth.isAdmin()) {
    return (
      <AssessmentAdminActionsMenu
        assessment={assessment}
        patchAssessment={patchAssessment}
        setAssessmentPath={setAssessmentPath}
        setError={setError}
        setAssessmentToChangeGroupFor={setAssessmentToChangeGroupFor}
        setAssessmentToChangeOwnerOf={setAssessmentToChangeOwnerOf}
        t={t}
      />
    );
  }
  return (
    <AssessmentActionButtons
      assessment={assessment}
      currentUserId={currentUserId}
      setAssessmentPath={setAssessmentPath}
      userIsInAGroup={userIsInAGroup}
      patchAssessment={patchAssessment}
      setAssessmentToChangeGroupFor={setAssessmentToChangeGroupFor}
      setError={setError}
    />
  );
}
