import { getNextItem } from 'common/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

export const PAGE_NAVIGATION_WARNING_MESSAGE = "Leaving this page will lose any unsaved changes. Are you sure you want to continue?";

export const ASSESSMENT_FORM_TITLES = {
  "profileinformation": "Profile Information",
  "farmmanagementpractices": "Farm Management Practices",
  "fields": "Fields and Crops",
  "livestock": "Livestock",
  "watermanagement": "Water Management",
  "energy": "Energy",
  "biodiversityandcommunity": "Biodiversity and Community",
  "test": "Test",
  "fertilisers": "Fertilisers",
  "cpp": "Crop Protection Products",
};

// Returns the next form in the sequence. Return value is null if the questionnaire is now complete
export function getNextForm(sectionId, assessmentFormIds) {
  if (!assessmentFormIds.includes(sectionId)) {
    return null;
  }

  return getNextItem(assessmentFormIds, sectionId, null);
}

export function NextSectionButton({ onSuccess }) {
  const { t } = useTranslation();
  return (
    <div className="regenagri-assessment-form-bottom-buttons">
      <Button
        data-testid="next-section"
        onClick={onSuccess}
      >
        {t('Next Section')}
      </Button>
    </div>
  );
}

export function renderTopButtons(previousForm, redirectWithModalIfRequired, assessmentId, t) {
  return (
    <div className="regenagri-assessment-form-top-buttons">
      {previousForm && (
        <Button
          data-testid="previous-section"
          onClick={() => redirectWithModalIfRequired(`/assessments/${assessmentId}/${previousForm}`)}
        >
          {t('Previous Section')}
        </Button>
      )}

      <div /> { /* To ensure that the "Back To Assessments" button is always on the right */}

      <Button
        data-testid="back-to-assessments"
        onClick={() => redirectWithModalIfRequired(window.localStorage.getItem("assessmentsRefUrl"))}
      >
        {t('Back To Assessments')}
      </Button>
    </div>
  );
}

export function isEditable(assessment, currentUserId, currentUserIsAdmin) {
  const {
    userId,
    certified,
    createdBy,
    createdByGroupAdmin,
  } = assessment.data;
  if (currentUserIsAdmin && !assessment.data.completed) {
    return true;
  }
  if (certified || createdByGroupAdmin) {
    return createdBy === currentUserId;
  }
  return userId === currentUserId;
}
