import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Select from "react-select";

import FilteredSelectionWrapper, { ValueContainer } from 'components/DropDownFilter';
import Translation from 'locales/Translation';

import './styles.scss';
import { setError } from 'components/RegenAgriForm/actions';
import BackendClientContext from 'contexts/BackendClient';
import {
  CompletedFilter,
  CreatedYearFilter,
  FarmTypeFilter,
  CountryFilter,
} from './commonFilters';
import { COUNTRIES } from './allCountriesList';

const { useTranslation } = Translation.setup();
const OLDEST_ASSESSMENT_YEAR = 2020;

export default function AdminFilterPanel(props) {
  const {
    selectedFilterData,
    setSelectedFilterData,
  } = props;

  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const yearSelectData = [];
  for (let i = OLDEST_ASSESSMENT_YEAR; i <= currentYear; i += 1) {
    yearSelectData.push({ value: i, label: i.toString() });
  }

  const [farmNameSelectData, setFarmNameSelectData] = useState([]);
  const backendClient = useContext(BackendClientContext);

  useEffect(() => {
    const getUserGroups = async () => {
      try {
        const farms = await backendClient.listAllFarms();
        setFarmNameSelectData(farms.map(farm => ({ value: farm.id, label: farm.name })));
      } catch (err) {
        setError(`${t("An error occurred whilst trying to retrieve the users groups")}: ${t(err.message)}`);
      }
    };
    getUserGroups();
  }, [backendClient, t]);

  const CREATOR_TYPE_MAPPING = { true: "Admin", false: "User" };
  const farmIdMappings = Object.fromEntries(farmNameSelectData.map(farm => ([farm.value, farm.label])));

  return (
    <>
      <div className="regenagri-filters-panel">
        <h4>Search assessments</h4>
        <Row>
          {FilteredSelectionWrapper({
            className: "col-md-12",
            selectData: farmNameSelectData,
            selectedFilterData,
            setSelectedFilterData,
            filterKey: "farmName",
            key: "farmName",
            placeHolderText: t("Search by farm name (multi)..."),
            filterKeyToOption: (farmId) => ({
              value: farmId,
              label: farmIdMappings[farmId],
            }),
          })}
        </Row>
        <Row>
          <CompletedFilter
            selectedFilterData={selectedFilterData}
            setSelectedFilterData={setSelectedFilterData}
            t={t}
          />
          {FilteredSelectionWrapper({
            className: "col-md-6",
            selectData: [
              {
                value: true,
                label: t("Admin"),
              },
              {
                value: false,
                label: t("User"),
              },
            ],
            selectedFilterData,
            setSelectedFilterData,
            filterKey: "certified",
            key: "certified",
            placeHolderText: t("Search by type of creator ..."),
            filterKeyToOption: (filter, label) => ({
              value: filter,
              label: CREATOR_TYPE_MAPPING[filter],
            }),
          })}
        </Row>
      </div>
      <div className="regenagri-filters-panel">
        <h4>Filter search results</h4>
        <Row>
          <CreatedYearFilter
            selectData={yearSelectData}
            selectedFilterData={selectedFilterData}
            setSelectedFilterData={setSelectedFilterData}
            t={t}
          />
          <CountryFilter
            selectData={COUNTRIES}
            selectedFilterData={selectedFilterData}
            setSelectedFilterData={setSelectedFilterData}
            t={t}
           />
          <FarmTypeFilter
            selectedFilterData={selectedFilterData}
            setSelectedFilterData={setSelectedFilterData}
            t={t}
          />
        </Row>
      </div>
    </>
  );
}
