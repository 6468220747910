const { default: FilteredSelectionWrapper } = require("components/DropDownFilter");

const FARM_TYPES = {
  "livestock": "Livestock",
  "dairy": "Dairy",
  "arable": "Arable",
  "topFruits": "Top Fruits",
  "freshProduce": "Fresh Produce",
};

export function CompletedFilter(props) {
  const {
    selectedFilterData, setSelectedFilterData, t,
  } = props;

  const completedOptions = [
    {
      value: true,
      label: formatBoolean(true),
    },
    {
      value: false,
      label: formatBoolean(false),
    },
  ];

  function formatBoolean(b) {
    return b ? t("yes") : t("no");
  }
  return FilteredSelectionWrapper({
    className: "col-md-6",
    selectData: completedOptions,
    selectedFilterData,
    setSelectedFilterData,
    filterKey: "completed",
    placeHolderText: t("Search by completed state ..."),
    filterKeyToOption: (filter) => ({
      value: filter,
      label: formatBoolean(filter),
    }),
  });
}

export function CreatedYearFilter(props) {
  const {
    selectData, selectedFilterData, setSelectedFilterData, t,
  } = props;
  return FilteredSelectionWrapper({
    className: "col-md-4",
    selectData,
    selectedFilterData,
    setSelectedFilterData,
    filterKey: "createdYear",
    placeHolderText: t("Search by created year ..."),
    filterKeyToOption: (filter) => ({
      value: filter,
      label: filter.toString(),
    }),
  });
}

export function CountryFilter(props) {
  const {
    selectData, selectedFilterData, setSelectedFilterData, t,
  } = props;

  const countryIdMappings = Object.fromEntries(selectData.map(country => ([country.value, country.label])));

  return FilteredSelectionWrapper({
    className: "col-md-4",
    selectData,
    selectedFilterData,
    setSelectedFilterData,
    filterKey: "country",
    placeHolderText: t("Search by country ..."),
    filterKeyToOption: (countryId) => ({
      value: countryId,
      label: countryIdMappings[countryId],
    }),
  });
}

export function FarmTypeFilter(props) {
  const {
    selectedFilterData, setSelectedFilterData, t,
  } = props;

  const farmTypeOptions = Object.entries(FARM_TYPES).map(([farmType, label]) => ({
    value: farmType,
    label: t(label),
  }));

  return FilteredSelectionWrapper({
    className: "col-md-4",
    selectData: farmTypeOptions,
    selectedFilterData,
    setSelectedFilterData,
    filterKey: "typeOfFarm",
    placeHolderText: t("Search by farm type ..."),
    filterKeyToOption: (farmType) => ({
      value: farmType,
      label: t(FARM_TYPES[farmType]),
    }),
  });
}
