import moment from 'moment';

import { dataFilterString, dataFilterValue } from './utils';

const showAssessmentForFarmType = (assessment, requiredFarmTypes) => {
  if (!requiredFarmTypes || !requiredFarmTypes.length) {
    return true;
  }
  return requiredFarmTypes.some((farmType) => assessment.typeOfFarm && assessment.typeOfFarm[farmType]);
};

export function showAssessment(assessment, filters) {
  if (!filters) {
    return true;
  }
  const toYear = (dateTime) => moment(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSS').year();
  return dataFilterString(assessment.farmName, filters.farmName)
    && dataFilterValue(assessment.completed, filters.completed)
    && dataFilterValue(assessment.certified, filters.certified)
    && dataFilterValue(toYear(assessment.created), filters.createdYear)
    && dataFilterString(assessment.country, filters.country)
    && showAssessmentForFarmType(assessment, filters.typeOfFarm);
}

export function filterAssessments(assessments, selectedAssessments, selectedFilterData) {
  return assessments
    .sort((a, b) => (b.modified.localeCompare(a.modified)))
    .filter(
      (assessment) => selectedAssessments.has(assessment.id) || showAssessment(assessment, selectedFilterData)
    );
}
