const colours = {
  GREEN: "rgb(2, 170, 44)",
  BLUE: "rgb(7, 163, 211)",
  ORANGE: "rgb(201, 116, 5)",
  RED: "rgb(160, 4, 4)",
  GREY: "#999",
};

function getScoreColour(percentage) {
  if (percentage >= 65) {
    return colours.GREEN;
  }
  return colours.RED;
}

function getComponentScoreColour(component) {
  // To understand what the function is supposed to do, go straight to the unit tests.
  // The requirements for this function were specified case by case (for every score/maxScore combination)
  // and the function was then TDD-ed accordingly.
  if (component.customColour) {
    return colours[component.customColour];
  }

  const { score, maxScore } = component;
  if (score >= maxScore) {
    // score can exceed maxScore due to bonus points
    return colours.GREEN;
  }

  if (score <= 0) {
    return colours.RED;
  }

  if (score === 1 && maxScore >= 3) {
    return colours.ORANGE;
  }

  if (score === 2 && maxScore === 5) {
    return colours.ORANGE;
  }

  return colours.BLUE;
}

module.exports = {
  getComponentScoreColour,
  getScoreColour,
  colours,
};
