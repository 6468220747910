import React from 'react';
import Select, { components } from "react-select";
import { setQueryParam } from 'common/queryString';

import { customStyles } from '../dropdownCustomStyles';

import './styles.scss';

export const ValueContainer = ({ children, getValue, ...props }) => {
  const values = getValue();
  let valueLabel;

  if (values.length > 2) {
    valueLabel = (
      <span className={"filter-pill"}>
        {values.length} selected
      </span>
    );
  } else if (values.length > 0) {
    valueLabel = (
      <React.Fragment>
        {
          values.slice(0, 2)
            .map(value => props.selectProps.getOptionLabel(value))
            .map(value => <span className={"filter-pill"} key={value}>{value}</span>)
        }
      </React.Fragment>
    );
  }

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel}
      {
        React.Children.toArray(children)
          .filter(child => ["Input", "DummyInput", "Placeholder"].includes(child.type.name))
      }
    </components.ValueContainer>
  );
};

export function FilteredSelection(props) {
  const {
    className,
    selectData,
    selectedFilterData,
    setSelectedFilterData,
    filterKey,
    placeHolderText,
    optionToFilterKey,
    filterKeyToOption,
  } = props;

  const handleChange = (filterData) => {
    const newFilters = {
      ...selectedFilterData,
      [filterKey]: filterData.map(optionToFilterKey),
    };
    setSelectedFilterData(newFilters);
    Object.entries(newFilters).map(([k, v]) => setQueryParam(k, v));
  };

  return (
    <Select
      styles={customStyles}
      className={`${className} regenagri-filter-panel-component`}
      closeMenuOnSelect={false}
      value={selectedFilterData[filterKey].map(filterKeyToOption)}
      isMulti
      options={selectData}
      onChange={handleChange}
      placeholder={placeHolderText}
      components={{ ValueContainer }}
      hideSelectedOptions={false}
    />
  );
}

export default function FilteredSelectionWrapper(props) {
  const {
    className,
    selectData,
    selectedFilterData,
    setSelectedFilterData,
    filterKey,
    placeHolderText,
    optionToFilterKey = (filterOption) => filterOption.value,
    filterKeyToOption,
  } = props;

  return (
    <FilteredSelection
      className={className}
      selectData={selectData}
      selectedFilterData={selectedFilterData}
      setSelectedFilterData={setSelectedFilterData}
      filterKey={filterKey}
      placeHolderText={placeHolderText}
      optionToFilterKey={optionToFilterKey}
      filterKeyToOption={filterKeyToOption}
    />
  );
}
