import React, { useContext } from 'react';
import { saveAs } from 'file-saver';

import {
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import config from 'common/config';

import AuthContext from 'contexts/Auth';
import BackendClientContext from 'contexts/BackendClient';
import { downloadReport } from './utils';

function OpenAssessmentMenuItem({
  assessment,
  sectionId,
  title,
  setAssessmentPath,
  t,
}) {
  return (
    <Dropdown.Item onClick={() => setAssessmentPath({
      assessmentId: assessment.id,
      sectionId,
    })}>
      { t(title) }
    </Dropdown.Item>
  );
}

export function AssessmentAdminActionsMenu({
  assessment,
  patchAssessment,
  setAssessmentPath,
  setError,
  setAssessmentToChangeGroupFor,
  setAssessmentToChangeOwnerOf,
  t,
}) {
  const backendClient = useContext(BackendClientContext);

  const reopenAssessment = async () => {
    try {
      await backendClient.reopenAssessment(assessment.id);
      patchAssessment(assessment.id, { completed: false });
    } catch (err) {
      setError(`${t("Unable to reopen assessment:")} ${t(err.message)}`);
    }
  };

  const menuItems = [];
  if (!assessment.completed) {
    menuItems.push(<OpenAssessmentMenuItem
      assessment={assessment}
      sectionId={config.firstAssessmentFormId}
      title={"Edit"}
      key={"edit-assessment"}
      setAssessmentPath={setAssessmentPath}
      t={t}
    />);
  } else {
    menuItems.push(<OpenAssessmentMenuItem
      assessment={assessment}
      sectionId={config.firstAssessmentFormId}
      title={"View"}
      key={"view-assessment"}
      setAssessmentPath={setAssessmentPath}
      t={t}
    />);
  }
  if (assessment.completed) {
    menuItems.push(<OpenAssessmentMenuItem
      assessment={assessment}
      sectionId={"scorecard"}
      title={"View Scorecard"}
      key={"view-scorecard"}
      setAssessmentPath={setAssessmentPath}
      t={t}
    />);
    menuItems.push(
      <Dropdown.Item
        onClick={() => reopenAssessment()}
        key={"reopen-assessment"}
      >
        {t("Reopen Assessment")}
      </Dropdown.Item>
    );
    if (assessment.completed && patchAssessment) {
      menuItems.push(<>
        <Dropdown.Item
          assessment={assessment}
          onClick={() => { setAssessmentToChangeGroupFor({ id: assessment.id, groups: assessment.groups }); }}
          key={"change-group"}
        >
          {t("Change Group")}
        </Dropdown.Item>
      </>);
    }
    menuItems.push(
      <Dropdown.Item
        onClick={() => downloadReport(assessment, backendClient, setError)}
        key={"download-audit-report"}
      >
        {t("Download – Assessment results")}
      </Dropdown.Item>
    );
    if (assessment.completed && patchAssessment) {
      menuItems.push(<>
        <Dropdown.Item
          assessment={assessment}
          onClick={() => setAssessmentToChangeOwnerOf(assessment)}
          key={"change-owner"}
        >
          {t("Change Assessment Owner")}
        </Dropdown.Item>
      </>);
    }
  }
  if (menuItems.length === 0) {
    return null;
  }
  return (
    <DropdownButton title={t("Actions")}>
      {menuItems}
    </DropdownButton>
  );
}
