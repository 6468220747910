import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterAssessments } from 'common/assessmentFiltering';
import PaginatedTable from 'components/PaginatedTable';
import BackendClientContext from 'contexts/BackendClient';

export function AssessmentsTableGridPaginated({
  mapAssessmentToTableRow,
  selectedAssessments,
  selectedFilterData,
}) {
  const { t } = useTranslation();
  const backendClient = useContext(BackendClientContext);
  const [error, setError] = useState(null);

  async function fetchAssessments(limit, skip, farm, completed, certified) {
    const res = await backendClient.getAssessments(
      '',
      limit,
      farm.length ? 0 : skip,
      farm || null,
      completed || null,
      certified || null
    );
    const filterData = { ...selectedFilterData };
    delete filterData.farmName;
    delete filterData.completed;
    delete filterData.certified;
    const assessments = filterAssessments(res.assessments, selectedAssessments, filterData);
    return {
      data: assessments,
      totalRecords: res.totalRecords,
      lastPageOfResults: res.assessments.length < limit,
    };
  }

  function getAssessmentRows(assessments) {
    return assessments.map(mapAssessmentToTableRow);
  }

  const getAssessmentsTable = () => {
    const columns = [
      { title: t("Farm Name") },
      { title: t("Date") },
      { title: t('Last Updated') },
      { title: t('Completed'), className: "regenagri-assessments-completed" },
      { title: t('Actions'), className: "regenagri-assessments-action" },
      { title: t('Select'), className: "regenagri-assessments-checkbox" },
    ];
    return (
      <PaginatedTable
        columns={columns}
        fetchData={fetchAssessments}
        fetchFinally={() => {}}
        setError={setError}
        limit={50}
        className="regenagri-assessments-table"
        farm={selectedFilterData.farmName}
        completed={selectedFilterData.completed}
        certified={selectedFilterData.certified}
        selectedFilterData={selectedFilterData}
      >
        {(data) => getAssessmentRows(data)
        }
      </PaginatedTable>
    );
  };
  return (
    <div>
      <h2>Assessments</h2>
      {getAssessmentsTable()}
    </div>
  );
}
