import React from 'react';
import { Row } from 'react-bootstrap';

import FilteredSelectionWrapper from 'components/DropDownFilter';
import Translation from 'locales/Translation';
import { toFilterValue } from './utils';

import './styles.scss';
import {
  CompletedFilter,
  CountryFilter,
  CreatedYearFilter,
  FarmTypeFilter,
} from './commonFilters';

const { useTranslation } = Translation.setup();

export default function FilterPanel(props) {
  const {
    farmNameSelectData,
    yearSelectData,
    countrySelectData,
    selectedFilterData,
    setSelectedFilterData,
  } = props;

  const { t } = useTranslation();

  return (
    <div id="regenagri-filter-panel">
      <Row>
        { FilteredSelectionWrapper({
          className: "col-md-6",
          selectData: farmNameSelectData,
          selectedFilterData,
          setSelectedFilterData,
          filterKey: "farmName",
          placeHolderText: t("Search by farm name ..."),
          optionToFilterKey: (filterOption) => filterOption.label,
          filterKeyToOption: (filter) => ({
            value: toFilterValue(filter),
            label: filter,
          }),
        })}
        <CompletedFilter
          selectedFilterData={selectedFilterData}
          setSelectedFilterData={setSelectedFilterData}
          t={t}
        />
      </Row>
      <Row>
        <CreatedYearFilter
          selectData={yearSelectData}
          selectedFilterData={selectedFilterData}
          setSelectedFilterData={setSelectedFilterData}
          t={t}
        />
        <CountryFilter
          selectData={countrySelectData}
          selectedFilterData={selectedFilterData}
          setSelectedFilterData={setSelectedFilterData}
          t={t}
        />
        <FarmTypeFilter
          selectedFilterData={selectedFilterData}
          setSelectedFilterData={setSelectedFilterData}
          t={t}
        />
      </Row>
    </div>
  );
}
