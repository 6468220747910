import React, { useContext, useState } from "react";
import {
  Alert,
  Button,
  Modal,
} from "react-bootstrap";

import BackendClientContext from 'contexts/BackendClient';
import Translation from 'locales/Translation';
import Spinner from "./Spinner";

const { useTranslation } = Translation.setup();

export default function ChangeAssessmentOwner(props) {
  const {
    assessmentToChangeOwnerOf,
    setAssessmentToChangeOwnerOf,
  } = props;

  const { t } = useTranslation();

  const backendClient = useContext(BackendClientContext);

  const [ownerEmail, setOwnerEmail] = useState(assessmentToChangeOwnerOf && assessmentToChangeOwnerOf.owner);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(null);

  const hideModal = () => {
    setError(null);
    setAssessmentToChangeOwnerOf(null);
  };

  const changeAssessmentOwner = async () => {
    setShowSpinner(true);
    try {
      await backendClient.changeAssessmentOwner(assessmentToChangeOwnerOf.id, ownerEmail);
      hideModal();
      setShowSpinner(false);
    } catch (err) {
      setError(`${t("Unable to change owner of the assessment:")} ${t(err.message)}`);
      setShowSpinner(false);
    }
  };

  return (
    <Modal show={!!assessmentToChangeOwnerOf}>
      <Modal.Header>
        <Modal.Title>{`${t('Set a new owner for')} ${assessmentToChangeOwnerOf?.farmName}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {showSpinner ?
          <Spinner />
          : (<input
            className='form-control'
            placeholder={t("Email Address")}
            aria-label="emailaddress"
            data-testid="email-address"
            onChange={(e) => setOwnerEmail(e.currentTarget.value)}
          />
          )
        }
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="modal-proceed-button"
          onClick={() => changeAssessmentOwner(assessmentToChangeOwnerOf.id, ownerEmail)}
          variant="primary"
        >
          {t('Set new owner')}
        </Button>
        <Button
          data-testid="modal-cancel-button"
          onClick={() => {
            setAssessmentToChangeOwnerOf(null);
            setOwnerEmail(null);
            setError(null);
          }}
          variant="secondary"
          disabled={showSpinner}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal >
  );
}
