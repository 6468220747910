import React, { useContext, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Spinner from 'components/Spinner';
import PaginatedTable from 'components/PaginatedTable';
import AddUser from 'components/AddUser';
import BackendClientContext from 'contexts/BackendClient';
import AuthContext from "contexts/Auth";
import { RedirectContext } from 'contexts/Routing';
import Translation from 'locales/Translation';

import "./styles.scss";

const { useTranslation, Trans } = Translation.setup();

function AssessmentButton({ userId, setRedirectUrl }) {
  return (
    <Button data-testid="assessmentsButton"
      onClick={() => {
        setRedirectUrl(`/admin/assessments?userId=${userId}`);
      }}
    >{'>'}</Button>
  );
}

function UserVerifiedState({ userVerified }) {
  const { t } = useTranslation();
  return (
    <span className={userVerified ? 'verified' : 'unverified'}>
      {userVerified ? t('Yes') : t('No')}
    </span>
  );
}

function TopButtons(props) {
  const { t } = useTranslation();
  const { setRedirectUrl } = props;
  return (
    <div className="regenagri-top-buttons">
      <Button
        className="regenagri-admin-groups-page-button"
        onClick={() => setRedirectUrl(`/admin/groups`)}
        disabled={false}>
        {t("Groups")}
      </Button>
    </div>
  );
}

export function AdminDashboard(props) {
  const {
    error,
    fetchUsers,
    isAdmin,
    redirectUrl,
    setError,
    setRedirectUrl,
    setUserPending,
    upgradeUser,
    userPending,
  } = props;

  const { t } = useTranslation();
  const Redirect = useContext(RedirectContext);

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />;
  }

  if (!isAdmin) {
    return <Redirect to="/login?authRequired=true"/>;
  }

  const getUserStatus = (user) => {
    if (user.id === userPending) {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        <span className={ user.isFullMember ? "full-member" : "limited" }>
          { user.isFullMember ? t('Full Member') : t('Limited') }
        </span>
        { !user.isFullMember && <Button onClick={() => upgradeUser(user.id)} className="upgrade-button">{t('Upgrade')}</Button> }
      </React.Fragment>
    );
  };

  const getUsersTable = () => {
    const columns = [
      { title: t('User Email') },
      { title: t('Email Verified') },
      { title: t('Member Status') },
      { title: t('Assessments') },
    ];
    return (
      <PaginatedTable
        columns={columns}
        fetchData={fetchUsers}
        fetchFinally={() => setUserPending(null)}
        setError={setError}
        limit={50}
      >
        {
          data => data.map((user, idx) => (
            <tr key={idx}>
              <td>{user.email}</td>
              <td><UserVerifiedState userVerified={user.verified} /></td>
              <td>{getUserStatus(user)}</td>
              <td>
                <AssessmentButton
                  userId={user.id}
                  setRedirectUrl={setRedirectUrl}
                />
              </td>
            </tr>
          ))
        }
      </PaginatedTable>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('regenagri')} - {t('admin dashboard')}</title>
      </Helmet>
      <div className="regenagri-admin-dashboard">
        <TopButtons setRedirectUrl={setRedirectUrl} />
        <h1>{t('Admin Dashboard')}</h1>
        <div className="divider"/>
        <p className="dashboard-info">
          <Trans i18nKey="welcomeToAdminDashboard" components={{ bold: <strong /> }} />
          <br/>{t('Here you may review any users who have registered on the system.')}
        </p>
        <h2>{t('assessments')}</h2>
        <a href="assessments">click here to see the list of all assessments</a>
        <div className="divider"/>
        { error && <Alert variant="danger">{ error }</Alert> }
        <div className="divider"/>
        <h2>{t('Registered Users')}</h2>
        <AddUser setError={setError} />
        { getUsersTable() }
      </div>
    </React.Fragment>
  );
}

export default function AdminDashboardWrapper() {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const backendClient = useContext(BackendClientContext);

  const [error, setError] = useState(null);
  const [userPending, setUserPending] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  async function fetchUsers(limit, skip) {
    const { users, totalRecords } = await backendClient.listAllUsers(limit, skip);
    return {
      data: users,
      totalRecords,
    };
  }

  const upgradeUser = async (userId) => {
    setUserPending(userId);

    try {
      await backendClient.upgradeUser(userId);
    } catch (err) {
      setError(`${t('An error occurred whilst trying to upgrade user:')} ${t(err.message)}`);
    }
  };

  return <AdminDashboard
    error={error}
    fetchUsers={fetchUsers}
    isAdmin={auth.isAdmin()}
    redirectUrl={redirectUrl}
    setError={setError}
    setRedirectUrl={setRedirectUrl}
    setUserPending={setUserPending}
    upgradeUser={upgradeUser}
    userPending={userPending}
  />;
}
