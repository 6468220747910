import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

import ChangeAssessmentGroup from 'components/ChangeAssessmentGroup';
import ChangeAssessmentOwner from 'components/ChangeAssessmentOwner';
import Spinner from 'components/Spinner';
import AuthContext from 'contexts/Auth';
import { RedirectContext } from 'contexts/Routing';
import Translation from 'locales/Translation';

import ChooseCombinedAssessmentOptions from 'components/ChooseCombinedAssessmentOptions';
import FilterPanel from './FilterPanel';
import AdminFilterPanel from './AdminFilterPanel';
import { ActionsButton } from './ActionsButton';
import { AssessmentsTableGrid } from './AssessmentTableGrid';
import { AssessmentsTableGridPaginated } from './AssessmentTableGridPaginated';
import {
  assessmentsToSelectData,
  buildFiltersFromUrl,
  CertifiedIcon,
  getCountryLabels,
  getYears,
} from './utils';

import './styles.scss';

const { useTranslation } = Translation.setup();

function MultiAssessmentAction({ action, assessmentIds }) {
  const Redirect = useContext(RedirectContext);
  const assessmentString = [...assessmentIds].join("&assessmentId=");
  return <Redirect push to={`/assessments/scorecard/${action}?assessmentId=${assessmentString}`} />;
}

function getClassNameForAssessment(assessment) {
  if (assessment.certified) {
    return 'regenagri-certified-assessment';
  }
  if (assessment.createdByGroupAdmin) {
    return 'regenagri-group-admin-assessment';
  }
  return '';
}

export function AssessmentsTable(props) {
  const {
    assessments,
    setAssessmentPath,
    toggleAssessmentSelected,
    farmNameSelectData,
    yearSelectData,
    countrySelectData,
    selectedAssessments,
    selectedFilterData,
    setSelectedFilterData,
    compareAssessments,
    setCompareAssessments,
    currentUserId,
    userIsInAGroup,
    assessmentToChangeGroupFor,
    assessmentToChangeOwnerOf,
    setAssessmentToChangeGroupFor,
    setAssessmentToChangeOwnerOf,
    patchAssessment,
    setError,
    allAssessmentsView,
  } = props;

  const { t } = useTranslation();

  const [showCombinedAssessmentOptions, setShowCombinedAssessmentOptions] = useState(false);

  if (!assessments) {
    return <Spinner />;
  }

  if (compareAssessments && selectedAssessments?.size > 1) {
    return <MultiAssessmentAction
      action='compare'
      assessmentIds={selectedAssessments}
    />;
  }

  if (!assessments.length) {
    return null;
  }

  function mapAssessmentToTableRow(assessment, idx) {
    return (
      <tr key={idx} className={getClassNameForAssessment(assessment)}>
        <td>
          <div>{assessment.farmName || t('Not yet specified')}</div>
          {assessment.certified &&
            <div className='icon-badge'>
              <CertifiedIcon /> {t('certified')}
            </div>
          }
        </td>
        <td>{moment(assessment.assessmentDate).format('Do MMM YYYY')}</td>
        <td>{moment.utc(assessment.modified).fromNow()}</td>
        <td className="regenagri-assessments-completed">{assessment.completed ? t("yes") : t("no")}</td>
        <td className="regenagri-assessments-action">
          <ActionsButton
              assessment={assessment}
              patchAssessment={patchAssessment}
              setAssessmentPath={setAssessmentPath}
              setError={setError}
              currentUserId={currentUserId}
              userIsInAGroup={userIsInAGroup}
              setAssessmentToChangeGroupFor={setAssessmentToChangeGroupFor}
              setAssessmentToChangeOwnerOf={setAssessmentToChangeOwnerOf}
          />
        </td>
        <td className="regenagri-assessments-checkbox">{assessment.completed && (
          <div>
            <input
              data-testid={`checkbox-${assessment.id}`}
              type="checkbox"
              defaultChecked={selectedAssessments.has(assessment.id)}
              onClick={() => toggleAssessmentSelected(assessment.id)} />
          </div>
        )}</td>
      </tr>
    );
  }

  return (
    <React.Fragment>
      <ChangeAssessmentGroup
        assessmentToChangeGroupFor={assessmentToChangeGroupFor}
        setAssessmentToChangeGroupFor={setAssessmentToChangeGroupFor}
        userId={currentUserId}
        patchAssessment={patchAssessment}
      />
      <ChangeAssessmentOwner
        assessmentToChangeOwnerOf={assessmentToChangeOwnerOf}
        setAssessmentToChangeOwnerOf={setAssessmentToChangeOwnerOf}
      />
      {
        allAssessmentsView ? (
          <>
            <AdminFilterPanel
              selectedFilterData={selectedFilterData}
              setSelectedFilterData={setSelectedFilterData}
            />
            <AssessmentsTableGridPaginated
              assessments={assessments}
              selectedAssessments={selectedAssessments}
              selectedFilterData={selectedFilterData}
              mapAssessmentToTableRow={mapAssessmentToTableRow}
            />
          </>
        ) : (
          <>
            <FilterPanel
              farmNameSelectData={farmNameSelectData}
              yearSelectData={yearSelectData}
              countrySelectData={countrySelectData}
              selectedFilterData={selectedFilterData}
              setSelectedFilterData={setSelectedFilterData}
            />
            <AssessmentsTableGrid
              assessments={assessments}
              selectedAssessments={selectedAssessments}
              selectedFilterData={selectedFilterData}
              mapAssessmentToTableRow={mapAssessmentToTableRow}
            />
          </>
        )
      }
      <div className="regenagri-assessments-action-buttons">
        <Button
          data-testid="combine-assessments-button"
          onClick={() => setShowCombinedAssessmentOptions(true)}
          disabled={selectedAssessments.size < 2}>
          {t("Combine")}
        </Button>
        <Button
          data-testid="compare-assessments-button"
          onClick={() => setCompareAssessments(true)}
          disabled={selectedAssessments.size < 2}>
          {t("Compare")}
        </Button>
        <ChooseCombinedAssessmentOptions
          selectedAssessments={selectedAssessments}
          show={showCombinedAssessmentOptions}
          setShow={setShowCombinedAssessmentOptions}
        />
      </div>
    </React.Fragment>
  );
}

export default function AssessmentsTableWrapper(props) {
  const {
    assessments,
    setAssessmentPath,
    patchAssessment,
    setError,
    allAssessmentsView,
  } = props;

  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const filtersFromUrl = buildFiltersFromUrl();

  const [farmNameSelectData, setFarmNameSelectData] = useState([]);
  const [yearSelectData, setYearSelectData] = useState([]);
  const [countrySelectData, setCountrySelectData] = useState([]);
  const [selectedFilterData, setSelectedFilterData] = useState(filtersFromUrl);
  const [selectedAssessments, setSelectedAssessments] = useState(new Set());
  const [assessmentToChangeGroupFor, setAssessmentToChangeGroupFor] = useState(null);
  const [assessmentToChangeOwnerOf, setAssessmentToChangeOwnerOf] = useState(null);
  const [compareAssessments, setCompareAssessments] = useState(false);

  const setAssessmentPathAndStoreRef = (assessmentObject) => {
    window.localStorage.setItem("assessmentsRefUrl", window.location.pathname + window.location.search);
    setAssessmentPath(assessmentObject);
  };

  const toggleAssessmentSelected = (assessmentId) => {
    if (selectedAssessments.has(assessmentId)) {
      selectedAssessments.delete(assessmentId);
    } else {
      selectedAssessments.add(assessmentId);
    }
    setSelectedAssessments(new Set(selectedAssessments));
  };

  useEffect(() => {
    if (assessments) {
      setFarmNameSelectData(assessmentsToSelectData(assessments, "farmName"));
      setYearSelectData(getYears(assessments));
      setCountrySelectData(getCountryLabels(assessments, t));
    }
  }, [assessments, t]);

  return <AssessmentsTable
    assessments={assessments}
    farmNameSelectData={farmNameSelectData}
    yearSelectData={yearSelectData}
    countrySelectData={countrySelectData}
    setAssessmentPath={setAssessmentPathAndStoreRef}
    selectedAssessments={selectedAssessments}
    toggleAssessmentSelected={toggleAssessmentSelected}
    compareAssessments={compareAssessments}
    setCompareAssessments={setCompareAssessments}
    currentUserId={auth.getUserId()}
    userIsInAGroup={!!auth.getGroupId()}
    selectedFilterData={selectedFilterData}
    setSelectedFilterData={setSelectedFilterData}
    assessmentToChangeGroupFor={assessmentToChangeGroupFor}
    assessmentToChangeOwnerOf={assessmentToChangeOwnerOf}
    setAssessmentToChangeGroupFor={setAssessmentToChangeGroupFor}
    setAssessmentToChangeOwnerOf={setAssessmentToChangeOwnerOf}
    patchAssessment={patchAssessment}
    setError={setError}
    allAssessmentsView={allAssessmentsView}
  />;
}
